import React from "react";
import { Link } from "react-router-dom";

function Footer(){
   
    return(
        <footer>

            <ul  className="flex justify-evenly text-cyan-200">
                
                <li>fiver</li>
                
                <li>github</li>
                
               < li>linkedin</li>
              
                <li>contact</li>
               
                
                
                
            </ul>
            <p> 2022 This website was designed and developed by Kentravious Colson</p>
        </footer>
    )
}

export default Footer