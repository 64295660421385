import './index.css'  
import { BrowserRouter, Route, Routes} from 'react-router-dom';
 
import Homepage from './Components/HOMEPAGE'
import Contact from './Components/CONTACT';
import Header from './Components/Header';

import Projects from './Components/PROJECTS';
import Footer from './Components/Footer';


function App() {
   
  return (
     
   <BrowserRouter>
   <div className=' mx-auto my-0 bg-black text-white text-center px-2 scroll-smooth'>
     <Header/>

      <Routes>
        <Route exact path='/' element={<Homepage className='mx-3 text-gray-600 bg-orange-400'/>}/>
        <Route path='/Components/PROJECTS' element={<Projects/>}/>
        <Route path='/Components/CONTACT' element={<Contact/>}/>
      </Routes>


         
      <Footer/>

   </div>
    
  </BrowserRouter>
     
  );
}

export default App;
