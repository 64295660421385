import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/GreenLogo.png'

function Header(props) {

    return (
        <header className=' mt-0 text-white bg-fixed px-4 py-3  m-auto w-full h-full'>
           <div className='bg-black pb-0 mt-0  rounded border-2 border-x-emerald-500 border-y-cyan-800 flex items-center shadow  shadow-white hover:shadow-emerald-700 opacity-80'  >
               
               <img className=' h-10 w-10 mx-auto ' src={Logo} />
            
            <h2 className='hidden md:block  mx-1 fixed'>Welcome to!</h2>
            <h1 className=' mx-6 text-cyan-200 text-2xl my-6'>Ken<span className='text-white animate-pulse'>{`{Tra}`}</span>vious Colson</h1>
            <p className='hover:font-semibold  mx-12 hidden sm:block'>Portfolio page</p>
    </div>
            <div className='hover:bg-white hover:text-cyan-600 w-full bg-emerald-700 flex  flex-wrap justify-evenly mt-2 px-4 py-2 rounded-xl shadow  shadow-white hover:shadow-emerald-700' >
                <Link to='./' className='hover:font-medium hover:text-emerald-700'>  Home </Link>
                <Link to='./Components/PROJECTS' className='hover:font-medium hover:text-emerald-700'>Projects </Link>
                <Link to='./Components/CONTACT' className='hover:font-medium hover:text-emerald-700'>contact </Link>
            </div>
           
            
        </header>
    )
}

export default Header