import React from "react";
import '../index'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
 


//Pictures
import GDPic1 from '../images/gdesigns/smokeybanner_1.jpg'
import GDPic2 from '../images/gdesigns/statement.jpg'
import GDPic3 from '../images/gdesigns/selflove.jpg'
import GDPic4 from '../images/gdesigns/advert1.jpg'
import GDPic5 from '../images/gdesigns/blkwmn_1_original.jpg'
import GDPic6 from '../images/gdesigns/keena.jpg'
import GDPic7 from '../images/gdesigns/chaoticAndIconic.jpg'
import GDPic8 from '../images/gdesigns/fridaynightlights_1_original.jpg'
import GDPic9 from '../images/gdesigns/happydollzrus_1_original.jpg'
import GDPic01 from '../images/gdesigns/zenyinergy_1_original.jpg'
import { Link } from "react-router-dom";

function Projects(){
    return(
        <main className="">
            <h2 class="text-cyan-200 text-xl m-2 rounded-3xl mx-6    border-2 border-x-emerald-500 border-y-cyan-800"> Projects</h2>
            
                <div>

            <div>
                <div >
                    <h3 class="text-cyan-200 text-xl m-2 "> Development & UI design</h3>
                    <p>Web application developed using HTML, CSS, REACT and tailwindcss  </p>
                    {/* web applications */  }
                    <Swiper>
                        <SwiperSlide>
                            <a href="https://kvc-blog.pages.dev"> <img src={GDPic1}/></a>
                        </SwiperSlide>
                    </Swiper>
                    
                </div>
                <hr/>

                
                
                <div >
                    <h3 class="text-cyan-200 text-xl m-2 my-0"> Design + Photography</h3>
                    <p>Designs I've created using GIMP and Dysner </p>
                    <p>swipe right</p>

                     <div>
                         {/* art gallery*/  }
                                   
                       <Swiper className=" ">
                    <SwiperSlide>
                        <div className="  mx-2 my-0 p-2">
                             <img src={GDPic1} alt="" class=" w-full h-full"/>
                         </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="h-26 mx-2 my-0 p-2">
                            <img src={GDPic2} alt="" class="w-full h-full "/>
                        </div>    
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="h-26 mx-2 my-0 p-2">
                             <img src={GDPic3} alt="" class=" w-full h-full"/>
                         </div>
                    </SwiperSlide >

                    <SwiperSlide> 
                        <div className="h-26 mx-2 my-0 p-2">
                             <img src={GDPic4} alt="" class="w-full h-full "/>
                         </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="h-26 mx-2 my-0 p-2">
                             <img src={GDPic5} alt="" class="w-full h-full"/>
                         </div> 
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="h-26 mx-2 my-0 p-2">
                             <img src={GDPic6} alt="" class="w-full h-full "/>
                         </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="h-26 mx-2 my-0 p-2">
                             <img src={GDPic7} alt="" class="w-full h-full "/>
                         </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="h-26 mx-2 my-0 p-2">
                             <img src={GDPic8} alt="" class="w-full h-full "/>
                         </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="h-26 mx-2 my-0 p-2">
                             <img src={GDPic9} alt="" class="w-full h-full "/>
                         </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        
                        <div className="h-26 mx-2 my-0 p-2">
                             <img src={GDPic01} alt="" class=" w-full h-full"/>
                         </div>  
                    </SwiperSlide>
                        </Swiper>  

                         
                                                
                     </div>
                    
                 
                </div>
                 
                
                <div class="text-cyan-200 text-xl m-2">
                    <h3>Creative Writing</h3>
                    <p> </p>
                </div>
            </div> 
                             
           
             
                </div>
            
            
         
        </main>
    )
}

export default Projects