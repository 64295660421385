import React from "react"
import introPic from '../images/techgif.gif'
import {Link} from 'react-router-dom'


function Homepage(){
    return(

        <main className="w-full h-full"  >

           <section className=" mx-2 py-2 px-2 flex  w-full " >

                <img src={introPic} alt="" className="w-1/2 h-full   "/>
                <div id="introduction" className=" w-1/2 p-3 flex flex-col mx-2 bg-emerald-700 rounded-lg">
                   
                    <h1 class=" text-lg mt-2">
                       Hi i'm!
                     <br/>
                        <strong className="text-cyan-200 text-2xl animate-pulse ">
                            Kentravous Colson
                        </strong>
                     <br/>
                    </h1>
                    <p className=" ">
                        Front-end dev 
                    <span className=" hidden sm:block md:text-left">
                        <br/> x UI designer x creative writer x graphic designer x creative writer x
                        </span> 
                    </p>

                </div>
                
            
            
        </section>
        
        <section  className="mx-2   py-4" id="services">
            <h2 class="mx-2 text-white text-xl bg-emerald-700   shadow  shadow-white hover:shadow-emerald-700 rounded-2xl font-extrabold "> 
             What I Do
             </h2>


            <div class="flex flex-col text-cyan-5-300 ">
                <div class=" bg-black  py-4 mx-2 mt-2 mb-0 text-emerald-200 opacity-75 ">

                    <h3 className='animate-bounce text-white text-xl rounded-3xl border-2 border-x-emerald-500 border-y-cyan-800 mx-6 '>
                        Design * Dev 
                    </h3>
                    <p className="tracking-wide ">Build & Deploy Responsive web applications <span className="hidden"> using the React Library along with the tailwindcss framework </span></p>
                </div> 
                
                <div class=" bg-black  py-4 mx-2 mt-2 mb-0 text-emerald-200 opacity-75">
                    <h3 class=" animate-bounce rounded-3xl mx-6 text-white text-xl border-2 border-x-emerald-500 border-y-cyan-800 ">
                         Graphic Design
                    </h3>
                    <p> 
                        Designing beautiful visuals and concepts <span className="hidden" >for advertisement and email marketing</span>
                    </p>
                </div> 

                <div class=" bg-black  py-4 mx-2 mt-2 mb-0 text-emerald-200 opacity-75">
                    <h3 class=" animate-bounce rounded-3xl mx-6 text-white text-xl border-2 border-x-emerald-500 border-y-cyan-800 ">
                         Creative Writer
                    </h3>
                    <p> 
                        Creating suspenseful and engaging content .<span className="hidden" >for advertisement and email marketing</span>
                    </p>
                </div> 


                
                <Link to='./Components/PROJECTS' 
                className="mx-2 my-2 rounded-full px-4 py-2 font-bold text-white hover:bg-white hover:text-emerald-700 bg bg-emerald-700">
                    My Work
                </Link>
            </div> 
            
            
        </section>
        
      
        
        
         
        
        </main>
    )
}

export default Homepage