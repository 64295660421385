import React from "react";

function Contact(){
    return(
        <main>
         <h2 class="text-cyan-200 text-xl">hire me!</h2>
         <p>download my resume</p>
         
        <p>email: Kentraviousc@gmail.com</p>
        <p>email: Kencoldev@gmail.com</p>
        <p>Phone: 1(478) 444 0665</p>

        
        </main>
    )
}

export default Contact 